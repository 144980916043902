import './App.css';
import React from 'react';
import { Route, Routes } from 'react-router';
import Home from './pages/index';
import { Link } from 'react-router-dom';
// import About from './pages/about';
// import IndexBlog from './pages/Blog';
// import DetailBlog from './pages/Blog/detail';
// import IndexMiniProject from './pages/MiniProject';
// import DetailMiniProject from './pages/MiniProject/detail';
import ReactGA from 'react-ga';
import DetailPage from './pages/blog/DetailPage';
import Menu from './components/Menu';

ReactGA.initialize('G-4C833JGMTS');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <div>
      <div className=" z-40 px-4 h-20 flex items-center md:px-0 lg:px-0 xl:px-0 md:grid-cols-4 ">
        <div className="flex container w-full justify-between items-center">
          <div>
            <Link to="/">
              <img src="/assets/logo.png" alt="Pad logo" className="h-20" />
            </Link>{' '}
          </div>{' '}
          <div>
            <div className="hidden phone:block">
              <Menu />
            </div>{' '}
            <ul className="desktop:block phone:hidden z-40">
              <li className="inline-block mr-16 hover:text-oren hover:font-bold">
                <Link to="/">Home </Link>{' '}
              </li>{' '}
              <li className="inline-block mr-16 hover:text-oren hover:font-bold">
                <Link to="/work">Work </Link>{' '}
              </li>{' '}
              <li className="inline-block mr-16 hover:text-oren hover:font-bold">
                <Link to="/blog">Blog </Link>{' '}
              </li>
              <li className="inline-block mr-16 hover:text-oren hover:font-bold">
                <Link to="/about">About </Link>{' '}
              </li>
              <li className="inline-block mr-16 hover:text-oren hover:font-bold">
                <Link to="/contact">Contact </Link>{' '}
              </li>
            </ul>{' '}
          </div>{' '}
        </div>
      </div>{' '}
      <Routes>
        <Route path="/" element={<Home />} /> <Route path="/p/:slug" element={<DetailPage />} />
        {/* <Route path="/about" element={<About/>}/>
              <Route path="/blog" element={<IndexBlog/>}/>
              <Route path="/blog/:slug" element={<DetailBlog/>}/>
              <Route path="/mini-project/" element={<IndexMiniProject/>}/>     
              <Route path="/mini-project/:slug" element={<DetailMiniProject/>}/>      */}{' '}
      </Routes>
      <footer className="container h-24 px-4 md:px-0 lg:px-0 xl:px-0 md:grid-cols-4 text-center">
        {' '}
        {/* <div className="h-24"></div>
              <small>Made with <a href="https://reactjs.org" className="text-blue-700">React</a>, <a href="https://tailwindcss.com" className="text-blue-700">Tailwind CSS</a> and <a href="https://supabase.io" className="text-blue-700">Supabase</a></small>
              <div className="block">
              {new Date().getFullYear()} &copy; <Link to="/" cla>aripadrian</Link>
              <div className="h-24"></div>
              </div> */}{' '}
      </footer>
    </div>
  );
}

export default App;
