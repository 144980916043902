import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"

function Menu() {
  const [menu, setMenu] = useState(false)
  const location = useLocation();

  useEffect(() => {
    setMenu(false)
  }, [location]);

 

    const handleMenu = (e) => {
      e.preventDefault()
      menu ? setMenu(false) : setMenu(true)
    }

    return (
        <div className={menu?"fixed z-40 right-0 w-screen top-0 bg-bg":"fixed z-40 right-0 top-0"}>
            <div className="h-20 flex items-center px-4 justify-between">
                <div>
            <Link to="/" className={menu ? 'block' : 'hidden'}>
              <img src="/assets/logo.png" alt="Pad logo" className="h-20" />
            </Link>
                </div>
            <div className="hidden phone:block" onClick={handleMenu} >
              {menu ?  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" ><path d="M12 11.293l10.293-10.293.707.707-10.293 10.293 10.293 10.293-.707.707-10.293-10.293-10.293 10.293-.707-.707 10.293-10.293-10.293-10.293.707-.707 10.293 10.293z" /></svg>: <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M24 18v1h-24v-1h24zm0-6v1h-24v-1h24zm0-6v1h-24v-1h24z" fill="#1040e2"/><path d="M24 19h-24v-1h24v1zm0-6h-24v-1h24v1zm0-6h-24v-1h24v1z"/></svg>}
            </div>
            </div>
        <ul className={menu?'h-screen w-full bg-bg px-4 text-right mt-20':'hidden h-screen w-full bg-bg px-4 text-right mt-20'}>
        <li className="text-3xl font-bold mb-6 hover:text-oren hover:font-bold">
                <Link className="border-b-2 border-black" to="/">
                Home
                </Link>  
              </li>
              <li className="text-3xl font-bold mb-6 hover:text-oren hover:font-bold">
                <Link className="border-b-2 border-black" to="/work" >
                Work
                </Link>  
              </li>
              <li className="text-3xl font-bold mb-6 hover:text-oren hover:font-bold">
                <Link className="border-b-2 border-black" to="/blog">
                Blog
                </Link>  
              </li>
              <li className="text-3xl font-bold mb-6 hover:text-oren hover:font-bold">
                <Link className="border-b-2 border-black" to="/about">
                About
                </Link>  
              </li>
              <li className="text-3xl font-bold mb-6 hover:text-oren hover:font-bold">
                <Link className="border-b-2 border-black" to="/contact">
                Contact
                </Link>
              </li>
        </ul>
        </div>
    )
}

export default Menu
