import React from "react"
import { useQuery } from "graphql-hooks"
import { useParams } from "react-router-dom"
import { renderRule,StructuredText } from 'react-datocms'
import { isCode } from 'datocms-structured-text-utils';
import './DetailPage.css'

import { Helmet } from "react-helmet";
import { CodeBlock, hopscotch } from "react-code-blocks";
import moment from "moment";
function DetailPage() {
    let {slug} = useParams()
    const QUERY = `query MyQuery {
        allPosts(filter: {slug: {eq: "${slug}"}}) {
            id
          title
          thumbnail {
            url
          }
          _createdAt
          content {
            value
          }
        }
      }
    `;
    const { load,err,data } = useQuery(QUERY)
    if (load) return 'Loading...'
    if (err) return 'Something bad happened'

    return (
        <div className="page-container phone:px-4">
            <Helmet>
                <title>
                {`${data?.allPosts[0]?.title} - Ari Padrian`}  
                </title>
            </Helmet>
            {
                data?.allPosts.map (r=>{
                   return (
                       <div key={r.id}>
                         <div className="mt-14 mb-10 w-full">
                           <span>{moment(r._createdAt).format("MMM DD, YYYY")}</span>
                             <h1 className="desktop:text-6xl phone:text-3xl mt-4 font-medium">
                                 {r.title}
                             </h1>
                         </div>
                         <div className="mb-10">
                            <img className="rounded-xl h-full w-full object-cover" src={r.thumbnail?r.thumbnail.url:'https://via.placeholder.com/500x300'} alt="" />
                         </div>
                           <StructuredText data={r.content} customRules={[
                                renderRule(isCode, ({ node, key }) => {
                                   return (
                                       
                                       <CodeBlock
                                       key={key}
                                       text={node.code}
                                       language={node.language}
                                       showLineNumbers={true}
                                       theme={hopscotch}
                                     />
                                   );
                                 })
                           ]} />
                       </div>
                   )
               })
              } 
            
        </div>
    )
}

export default DetailPage
