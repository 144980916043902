// import {Link} from 'react-router-dom'
import React from 'react'
import Helmet from 'react-helmet'
import { useQuery } from "graphql-hooks";
import { Link } from 'react-router-dom';
import moment from 'moment';
function Home() {
    const QUERY = `query Homepage {
        allPosts {
            id
            title
            slug
            thumbnail {
                url
            }
            _status
            _createdAt
        }}
    `;
    const { load,err,data } = useQuery(QUERY)
    if (load) return 'Loading...'
    if (err) return 'Something bad happened'

    return (
        <div>
            <Helmet>
                <title>Just another exploration from @aripadrian</title>
            </Helmet>
            <div>
                <div className="h-screen phone:px-4 w-full relative flex desktop:items-center container desktop:-mt-20">
                    <div className="phone:mt-40">
                    <h1 className="text-6xl font-bold block">Hello there !</h1>
                    <span className="block text-2xl desktop:w-2/3 mt-4">I am Ari Padrian. a Front End Developer focussing on web platform development.</span>
                    </div>
                </div>
                <img className="absolute bottom-0 right-0" src="/assets/element.png" alt="Pad logo"/>
            </div>


            <div className="h-screen phone:px-4 desktop:px-0 bg-oren w-screen -mt-24 pt-20 ">
                <div className="container">
                    <div className="flex  gap-3">
                        <h1 className="text-5xl text-white font-bold">Latest From Blog</h1>
                        <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="23" cy="23" r="23" fill="white"/>
                            <path d="M16 23.0476L22 11L28.75 13.9206L25.75 19.7619L31 27.0635L16 34L22 27.0635L16 23.0476Z" fill="#E91F24"/>
                            </svg>

                    </div>

                    <div className="mt-16">
                        <div className="flex overflow-x-scroll hide-scroll-bar rounded-3xl">
                            <div className="flex flex-nowrap gap-4 ">
                                {
                                        data?.allPosts.map(d=> {
                                            return (
                                                    <Link to={`/p/${d.slug}`} key={d.id} className="w-blog bg-white hover:shadow h-auto rounded-3xl p-4  hover:cursor-pointer hover:border">
                                                    <img className="rounded-3xl h-40 w-full object-cover" src={d.thumbnail?d.thumbnail.url:'https://via.placeholder.com/500x300'} alt="" />
                                                    <span className="block text-xs text-gray-500 mt-4">{moment(d._createdAt).format("DD MMM YYYY")}</span>
                                                    <h2 className="text-2xl leading-7 mt-2">{d.title}</h2>
                                                </Link>
                                            )
                                        })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="mt-10">
                        <a className="text-white text-xl pb-2 border-b-2 pr-4" href="/">See All Articles</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home
